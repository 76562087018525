<template>
    <section id="settings-subscription">
        <h2 class="pb-2">{{ $t('subscription') }}</h2>
        <PaymentTeaser v-for="(p, pind) in payments"
                       :payment="p"
                       :student="$store.state.user"
                       :readonly="true"
                       @refresh="refresh"
                       :key="'payment-'+pind"/>
        <div v-if="loaded && payments.length == 0">{{ $t('not_found') }}</div>
    </section>
</template>

<script>
    import EventBus from '@/services/event-bus'
    import { StudentPageService } from '@/services/api.service'
    import PaymentTeaser from "@/views/teasers/PaymentTeaser"

    export default {
        components: {
            PaymentTeaser
        },
        data() {
            return {
                page: 1,
                loaded: false,
                payments: []
            }
        },
        mounted() {
            EventBus.$emit('breadcrumbs',[
                { to: '/settings', title: this.$t('settings') },
                { to: '/settings/subscription', title: this.$t('subscription') },
            ])
            StudentPageService.payments(this.page).then(res => {
                this.payments = res.data.data.data
                this.loaded = true
            })
        },
        methods: {
            refresh() {

            }
        }
    }
</script>


